import { CaretLeft, CaretRight } from '@phosphor-icons/react'
import { EmblaCarouselType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import { shuffle } from 'lodash-es'
import Link from 'next/link'
import { useCallback, useEffect, useState } from 'react'
import { TeamSliderBlock } from '../../@types/statamic'
import { getSpecificTeamMembers, getTeamMembers } from '../../AdminApiClient'
import Title from '../../components/elements/Title'
import Container from '../../components/layout/Container'
import Button from '@/src/components/core/Button'
import TeamMember from '@/src/components/elements/TeamMember'

type TeamSliderBlockProps = Awaited<ReturnType<typeof getStaticProps>>

export function getTitle({ title }: TeamSliderBlock) {
  return title
}

const INSTRUCTOR_LIMIT = 2

export async function getStaticProps({
  title,
  roles,
  locations,
  opt_team_members,
  show_all_link,
}: TeamSliderBlock) {
  let team
  if (opt_team_members && opt_team_members.length > 0) {
    team = await getSpecificTeamMembers(
      opt_team_members.map((team_member) => team_member.value)
    )
  } else {
    team = await getTeamMembers(
      roles.map((role) => role.value),
      locations?.map((location) => location.value)
    )
    if (team.length === 0) {
      team = (
        await getTeamMembers(
          roles.map((role) => role.value),
          undefined
        )
      ).slice(0, 3)
    }
  }

  team = shuffle(team)

  const instructors = team.filter((member) =>
    member.roles.includes('Kursinstruktor:innen')
  )
  const rest = team.filter(
    (member) => !member.roles.includes('Kursinstruktor:innen')
  )

  const tempRestCount = Math.min(9 - INSTRUCTOR_LIMIT, rest.length)
  const instructorCount = Math.min(9 - tempRestCount, instructors.length)
  const restCount = Math.max(tempRestCount, 9 - instructorCount)

  team = shuffle([
    ...instructors.slice(0, instructorCount),
    ...rest.slice(0, restCount),
  ])

  return {
    team,
    title,
    show_all_link,
    locationIds: locations?.map((location) => location.value) ?? [],
  }
}

export default function Component(teamSliderBlock: TeamSliderBlockProps) {
  return (
    <div className="md:pb-12 lg:pb-16">
      <Container fullPage className="lg:max-w-container">
        <div className="lg:hidden">
          <Mobile {...teamSliderBlock} />
        </div>
        <div className="hidden lg:block">
          <Desktop {...teamSliderBlock} />
        </div>
      </Container>
    </div>
  )
}

function Mobile({
  title,
  team,
  show_all_link,
  locationIds,
}: TeamSliderBlockProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel()
  const [prevDisabled, setPrevDisabled] = useState(true)
  const [nextDisabled, setNextDisabled] = useState(true)

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevDisabled(!emblaApi.canScrollPrev())
    setNextDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect])

  const prev = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])

  const next = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  return (
    <>
      <div className="mx-5">
        <Title style={2}>{title}</Title>
      </div>
      <div className="mt-6 overflow-hidden pb-10" ref={emblaRef}>
        <ul className="flex select-none">
          {team.map((member) => (
            <div className="ml-5 min-w-0 flex-[0_0_80%] last:mr-5 md:flex-[0_0_40%]">
              <TeamMember
                key={member.slug}
                member={member}
                locationIds={locationIds}
              />
            </div>
          ))}
        </ul>
      </div>
      {!(prevDisabled && nextDisabled) && (
        <div className="mx-5 mt-6 flex items-center justify-between">
          {show_all_link ? (
            <Link href="/ueber-uns" className="font-bold text-purple-600">
              Zum kompletten Team
            </Link>
          ) : (
            <div />
          )}
          <div className="flex shrink-0 space-x-3">
            <Button
              size="icon-small"
              icon={<CaretLeft weight="bold" />}
              onClick={prev}
              version="purple-outlined"
              disabled={prevDisabled}
            />
            <Button
              size="icon-small"
              icon={<CaretRight weight="bold" />}
              onClick={next}
              version="purple-outlined"
              disabled={nextDisabled}
            />
          </div>
        </div>
      )}
    </>
  )
}

function Desktop({
  title,
  team,
  show_all_link,
  locationIds,
}: TeamSliderBlockProps) {
  return (
    <div className="mx-5">
      <Title style={2}>{title}</Title>
      <ul className="mt-11 grid w-full grid-cols-1 gap-x-8 gap-y-[88px] sm:grid-cols-2 lg:grid-cols-3">
        {team.map((member) => (
          <TeamMember
            key={member.slug}
            member={member}
            locationIds={locationIds}
          />
        ))}
      </ul>
      {show_all_link && (
        <div className="pt-16">
          <Link href="/ueber-uns" className="font-bold text-purple-600">
            Zum kompletten Team
          </Link>
        </div>
      )}
    </div>
  )
}
