import { shuffle } from 'lodash-es'
import trim from 'lodash-es/trim'
import { TeamRole } from './@types/statamic'
import { Mandatory } from './@types/util'
import { Website } from '@/src/@types/blinkadmin'

const API_ADMIN_URL = process.env.NEXT_PUBLIC_BLINK_ADMIN_URL
const NOHE_API_ADMIN_URL = process.env.API_ADMIN_URL

export async function get<Response = unknown>(
  url: string,
  params?: Record<string, string>,
  // TODO: Remove once UpcomingFirstAidCourses endpoint is available in Blink
  nohe?: boolean
): Promise<Response> {
  url = trim(url, '/')

  if (params) {
    url += '?' + new URLSearchParams(params).toString()
  }

  return await (
    await fetch(`${nohe ? NOHE_API_ADMIN_URL : API_ADMIN_URL}/${url}`)
  ).json()
}

export async function getTeamMembers(
  roles: TeamRole[] = [],
  locations: string[] = []
): Promise<Mandatory<Website.TeamMember, 'image'>[]> {
  const members = await get<Website.TeamMember[]>(
    `api/website/v2/team-members?filter[roles]=${roles.join(',')}&filter[locations]=${locations.join(',')}`
  )
  return shuffle(
    members.filter(
      (member): member is Mandatory<Website.TeamMember, 'image'> =>
        !!member.image
    )
  )
}

export async function getSpecificTeamMembers(
  teamMemberIds: string[]
): Promise<Mandatory<Website.TeamMember, 'image'>[]> {
  const members = await get<Website.TeamMember[]>(
    `api/website/v2/team-members?filter[members]=${teamMemberIds.join(',')}`
  )

  return members.filter(
    (member): member is Mandatory<Website.TeamMember, 'image'> => !!member.image
  )
}
