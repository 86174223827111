import {
  Airplane,
  Baby,
  Bird,
  BowlFood,
  Calendar,
  CalendarCheck,
  CarProfile,
  Chats,
  Coffee,
  Cookie,
  Gauge,
  GlobeHemisphereWest,
  MapPin,
  Motorcycle,
  MusicNotes,
  PawPrint,
  PersonSimpleTaiChi,
  QuestionMark,
  Smiley,
  Sparkle,
  Star,
  Trophy,
  User,
  Users,
  X,
} from '@phosphor-icons/react'
import { DialogClose, DialogTitle } from '@radix-ui/react-dialog'
import clsx from 'clsx'
import Link from 'next/link'
import { ReactNode, useState } from 'react'
import Picture from './Picture'
import { Website } from '@/src/@types/blinkadmin'
import Button from '@/src/components/core/Button'
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '@/src/components/core/Dialog'
import { formatGerman } from '@/src/components/core/lib/date'
import ShowMore from '@/src/components/elements/ShowMore'

const IconMap: { [key: string]: ReactNode } = {
  baby: <Baby size={24} weight="fill" className="text-blue-600" />,
  users: <Users size={24} weight="fill" className="text-blue-600" />,
  'bowl-food': <BowlFood size={24} weight="fill" className="text-blue-600" />,
  'person-simple-tai-chi': (
    <PersonSimpleTaiChi size={24} weight="fill" className="text-blue-600" />
  ),
  'music-notes': (
    <MusicNotes size={24} weight="fill" className="text-blue-600" />
  ),
  'car-profile': (
    <CarProfile size={24} weight="fill" className="text-blue-600" />
  ),
  bird: <Bird size={24} weight="fill" className="text-blue-600" />,
  smiley: <Smiley size={24} weight="fill" className="text-blue-600" />,
  cookie: <Cookie size={24} weight="fill" className="text-blue-600" />,
  'paw-print': <PawPrint size={24} weight="fill" className="text-blue-600" />,
  airplane: <Airplane size={24} weight="fill" className="text-blue-600" />,
  'globe-hemisphere-west': (
    <GlobeHemisphereWest size={24} weight="fill" className="text-blue-600" />
  ),
  trophy: <Trophy size={24} weight="fill" className="text-blue-600" />,
  coffee: <Coffee size={24} weight="fill" className="text-blue-600" />,
  gauge: <Gauge size={24} weight="fill" className="text-blue-600" />,
  motorcycle: <Motorcycle size={24} weight="fill" className="text-blue-600" />,
  sparkle: <Sparkle size={24} weight="fill" className="text-blue-600" />,
}

export default function TeamMember({
  member,
  locationIds,
}: {
  member: Website.TeamMember
  locationIds?: string[]
}) {
  return (
    <li className="aspect-h-2 aspect-w-3 relative">
      <Picture
        fill
        permalink={member.image}
        alt={`Bild von ${member.name}`}
        sizes="(min-width: 1024px) 33vw, (min-width: 768px) 66vw, 100vw"
        className="rounded-xl object-cover"
      />
      <div>
        <div className="absolute inset-x-0 -bottom-8 mx-3 flex items-center justify-between space-x-4 rounded-xl bg-white px-4 py-3 shadow">
          <div>
            <p className="text-lg font-extrabold">{member.name}</p>
            <p className="text-gray-600">{member.short_description}</p>
          </div>
          {member.linkedin && (
            <Button
              as={Link}
              size="icon"
              version="purple-outlined"
              href={member.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              icon={
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.53844 7.75H3.41344V17.75H6.53844V7.75ZM6.81969 4.3125C6.82656 3.31813 6.02594 2.50687 5.03219 2.5H4.97594C3.97469 2.50063 3.16344 3.3125 3.16406 4.31313C3.16469 5.31375 3.97531 6.12438 4.97594 6.125C5.96969 6.14938 6.79531 5.36375 6.81969 4.37V4.36875V4.3125ZM18.4134 11.675C18.4134 8.66875 16.5009 7.5 14.6009 7.5C13.3347 7.43687 12.1309 8.05062 11.4384 9.1125H11.3509V7.75H8.41344V17.75H11.5384V12.4313C11.4447 11.2975 12.2809 10.2994 13.4134 10.1938H13.5322C14.5259 10.1938 15.2634 10.8188 15.2634 12.3938V17.75H18.3884L18.4134 11.675Z"
                    fill="#6A29EC"
                  />
                </svg>
              }
            />
          )}
          {member.short_description?.includes('Fahrlehrer') &&
            member.feedback.length !== 0 &&
            member.facts.length !== 0 &&
            member.locations.length !== 0 && (
              <TeacherProfile member={member} locationIds={locationIds} />
            )}
        </div>
      </div>
    </li>
  )
}

const TeacherProfile = ({
  member,
  locationIds,
}: {
  member: Website.TeamMember
  locationIds?: string[]
}) => {
  const [tab, setTab] = useState<'info' | 'feedback'>('info')

  const bookingLink =
    member.locations
      .sort((a, b) =>
        (locationIds?.indexOf(a.id) ?? Infinity) >
        (locationIds?.indexOf(b.id) ?? Infinity)
          ? 1
          : -1
      )
      .find((location) => locationIds?.includes(location.id))?.booking_link ??
    member.locations[0]!.booking_link

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="icon" icon={<User weight="fill" />} />
      </DialogTrigger>
      <DialogContent
        mobileBottom={false}
        className="inset-0 max-h-none !max-w-[800px] translate-x-0 overflow-y-auto rounded-none md:left-1/2 md:top-16 md:max-h-[calc(100%-144px)] md:-translate-x-1/2 md:flex-row md:overflow-hidden md:rounded-lg md:data-[state=closed]:slide-out-to-left-1/2 md:data-[state=open]:slide-in-from-left-1/2"
      >
        <DialogClose className="absolute right-3 top-3 z-50 rounded-full bg-black/20 p-2 md:hidden">
          <X weight="bold" size={24} className="text-white" />
        </DialogClose>
        <div className="shrink-0 border-r border-blue-100 md:h-full md:w-[320px]">
          <div className="aspect-h-4 aspect-w-6 relative">
            <Picture
              fill
              permalink={member.image}
              alt={`Bild von ${member.name}`}
              sizes="100vw"
              className="object-contain object-center"
            />
          </div>
          <div className="mx-8 mt-6 flex flex-col items-center text-center">
            <DialogTitle className="text-2xl font-extrabold">
              {member.name}
            </DialogTitle>
            <p className="mt-1 text-sm text-gray-600">
              {member.short_description}
            </p>
            {member.short_sentence && (
              <p className="mt-4 text-gray-800">{member.short_sentence}</p>
            )}
            <Button
              as={Link}
              href={bookingLink}
              icon={<Calendar weight="fill" />}
              className="mt-4 w-full"
            >
              Probelektion buchen
            </Button>
          </div>
        </div>
        <div className="flex grow flex-col">
          <div className="hidden px-4 pt-4 text-right md:block">
            <DialogClose className="p-3 text-gray-600">
              <X weight="bold" size={24} />
            </DialogClose>
          </div>
          <div className="mt-8 space-x-5 border-b border-blue-100 px-5 md:mt-0 md:px-8">
            <button
              className={clsx('border-b-2 pb-1.5 pt-2.5', {
                'border-purple-600 font-bold': tab === 'info',
                'border-transparent': tab !== 'info',
              })}
              onClick={() => setTab('info')}
            >
              Informationen
            </button>
            <button
              className={clsx('border-b-2 pb-1.5 pt-2.5', {
                'border-purple-600 font-bold': tab === 'feedback',
                'border-transparent': tab !== 'feedback',
              })}
              onClick={() => setTab('feedback')}
            >
              Feedbacks
            </button>
          </div>
          {tab === 'info' && (
            <div className="overflow-y-auto px-5 py-6 md:px-8">
              <p className="font-bold">Steckbrief</p>
              <div className="mt-3 space-y-2">
                {member.since && (
                  <div className="flex items-center space-x-3">
                    <div className="rounded-full bg-blue-50 p-2">
                      <CalendarCheck
                        size={24}
                        weight="fill"
                        className="text-gray-600"
                      />
                    </div>
                    <p>
                      Fahrlehrer seit{' '}
                      {formatGerman(new Date(member.since), 'MMMM. yyyy')}
                    </p>
                  </div>
                )}
                {member.languages.length > 0 && (
                  <div className="flex items-center space-x-3">
                    <div className="rounded-full bg-blue-50 p-2">
                      <Chats
                        size={24}
                        weight="fill"
                        className="text-gray-600"
                      />
                    </div>
                    <p>{member.languages.join(', ')}</p>
                  </div>
                )}
                <div className="flex items-center space-x-3">
                  <div className="rounded-full bg-blue-50 p-2">
                    <MapPin size={24} weight="fill" className="text-gray-600" />
                  </div>
                  <p>
                    {member.locations
                      .map((location) => location.name)
                      .join(', ')}
                  </p>
                </div>
                <div className="flex items-center space-x-3">
                  <div className="rounded-full bg-blue-50 p-2">
                    <CarProfile
                      size={24}
                      weight="fill"
                      className="text-gray-600"
                    />
                  </div>
                  <p>
                    {member.cars.map((car) => car.name).join(', ')}, Automat{' '}
                    {member.cars.some(
                      (car) => car.transmission_type === 'manual'
                    )
                      ? '& Geschalten'
                      : ''}
                  </p>
                </div>
              </div>
              {member.facts.length > 0 && (
                <>
                  <p className="mt-6 font-bold">Facts über {member.name}</p>
                  <div className="mt-3 space-y-2">
                    {member.facts.map((fact) => (
                      <div className="flex items-center space-x-3">
                        <div className="rounded-full bg-blue-100 p-2">
                          {IconMap[fact.icon] ? (
                            <>{IconMap[fact.icon]}</>
                          ) : (
                            <QuestionMark
                              size={24}
                              weight="bold"
                              className="text-blue-600"
                            />
                          )}
                        </div>
                        <p>{fact.text}</p>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {member.feedback.length > 0 && (
                <>
                  <p className="mt-6 font-bold">Feedbacks von Schüler:innen</p>
                  <div className="mt-1">
                    {member.feedback.map((feedback) => (
                      <div className="mt-3">
                        <div className="rounded-lg bg-blue-50 p-6">
                          <div className="flex justify-between">
                            <div className="flex space-x-4">
                              <p
                                className={clsx(
                                  'flex size-12 items-center justify-center rounded-full text-center text-xl',
                                  getColorFromString(feedback.name)
                                )}
                              >
                                {feedback.name[0]}
                              </p>
                              <div>
                                <p className="font-bold">{feedback.name}</p>
                                <p className="text-gray-600">
                                  {formatGerman(
                                    new Date(feedback.date),
                                    'dd.MM.yyyy'
                                  )}
                                </p>
                              </div>
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              className="size-6"
                            >
                              <path
                                fill="#4285F4"
                                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                              />
                              <path
                                fill="#34A853"
                                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                              />
                              <path
                                fill="#FBBC05"
                                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                              />
                              <path
                                fill="#EA4335"
                                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                              />
                              <path fill="none" d="M1 1h22v22H1z" />
                            </svg>
                          </div>
                          <div className="mt-6 flex space-x-1">
                            {new Array(Math.ceil(feedback.rating))
                              .fill(null)
                              .map((_, index) => (
                                <Star
                                  key={index}
                                  size={20}
                                  weight="fill"
                                  className="shrink-0 text-yellow-600"
                                />
                              ))}
                            {new Array(Math.floor(5 - feedback.rating))
                              .fill(null)
                              .map((_, index) => (
                                <Star
                                  key={index}
                                  size={20}
                                  className="shrink-0 text-yellow-600"
                                />
                              ))}
                          </div>
                          <ShowMore>
                            {({
                              clampRef,
                              isClamped,
                              isExpanded,
                              setIsExpanded,
                            }) => (
                              <>
                                <p
                                  ref={clampRef}
                                  className={clsx('mt-4 text-gray-800', {
                                    'line-clamp-4': !isExpanded,
                                  })}
                                >
                                  {feedback.text}
                                </p>
                                {isClamped && !isExpanded && (
                                  <button
                                    onClick={() => setIsExpanded(true)}
                                    className="mt-4 text-sm font-bold text-purple-600 hover:text-purple-800"
                                  >
                                    Mehr anzeigen
                                  </button>
                                )}
                              </>
                            )}
                          </ShowMore>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
          {tab === 'feedback' && (
            <div className="overflow-y-auto px-5 py-6 md:px-8">
              <p className="font-bold">Feedbacks von Schüler:innen</p>
              <div className="mt-1">
                {member.feedback.map((feedback) => (
                  <div className="mt-3">
                    <div className="rounded-lg bg-blue-50 p-6">
                      <div className="flex justify-between">
                        <div className="flex space-x-4">
                          <p
                            className={clsx(
                              'flex size-12 items-center justify-center rounded-full text-center text-xl',
                              getColorFromString(feedback.name)
                            )}
                          >
                            {feedback.name[0]}
                          </p>
                          <div>
                            <p className="font-bold">{feedback.name}</p>
                            <p className="text-gray-600">
                              {formatGerman(
                                new Date(feedback.date),
                                'dd.MM.yyyy'
                              )}
                            </p>
                          </div>
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="size-6"
                        >
                          <path
                            fill="#4285F4"
                            d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                          />
                          <path
                            fill="#34A853"
                            d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                          />
                          <path
                            fill="#FBBC05"
                            d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                          />
                          <path
                            fill="#EA4335"
                            d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                          />
                          <path fill="none" d="M1 1h22v22H1z" />
                        </svg>
                      </div>
                      <div className="mt-6 flex space-x-1">
                        {new Array(Math.ceil(feedback.rating))
                          .fill(null)
                          .map((_, index) => (
                            <Star
                              key={index}
                              size={20}
                              weight="fill"
                              className="shrink-0 text-yellow-600"
                            />
                          ))}
                        {new Array(Math.floor(5 - feedback.rating))
                          .fill(null)
                          .map((_, index) => (
                            <Star
                              key={index}
                              size={20}
                              className="shrink-0 text-yellow-600"
                            />
                          ))}
                      </div>
                      <ShowMore>
                        {({
                          clampRef,
                          isClamped,
                          isExpanded,
                          setIsExpanded,
                        }) => (
                          <>
                            <p
                              ref={clampRef}
                              className={clsx('mt-4 text-gray-800', {
                                'line-clamp-4': !isExpanded,
                              })}
                            >
                              {feedback.text}
                            </p>
                            {isClamped && !isExpanded && (
                              <button
                                onClick={() => setIsExpanded(true)}
                                className="mt-4 text-sm font-bold text-purple-600 hover:text-purple-800"
                              >
                                Mehr anzeigen
                              </button>
                            )}
                          </>
                        )}
                      </ShowMore>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

const getColorFromString = (input: string) => {
  let hash = 0
  for (let i = 0; i < input.length; i++) {
    hash = hash + input.charCodeAt(i)
  }

  const index = Math.abs(hash) % FeedbackColors.length
  return FeedbackColors[index]
}

const FeedbackColors = [
  'bg-pink-500 text-white',
  'bg-red-500 text-white',
  'bg-blue-500 text-white',
  'bg-green-500 text-white',
  'bg-yellow-500',
]
