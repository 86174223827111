import { ReactNode, RefObject, useEffect, useRef, useState } from 'react'

const ShowMore = ({
  children,
}: {
  children: ({
    clampRef,
    isClamped,
  }: {
    clampRef: RefObject<HTMLParagraphElement>
    isClamped: boolean
    isExpanded: boolean
    setIsExpanded: (isExpanded: boolean) => void
  }) => ReactNode
}) => {
  const clampRef = useRef<HTMLParagraphElement>(null)
  const [isClamped, setIsClamped] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    const ref = clampRef.current
    if (!ref) return
    setIsClamped(ref.scrollHeight > ref.offsetHeight)
  }, [])

  return children({ clampRef, isClamped, isExpanded, setIsExpanded })
}

export default ShowMore
