import classNames from 'classnames'
import clsx from 'clsx'
import Image from 'next/image'
import { A11y, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { TestimonialsSliderBlock } from '../../@types/statamic'
import Container from '../../components/layout/Container'
import TestimonialSliderPlaceholder from '@/img/testimonial_slider_placeholder.png'
import Picture from '@/src/components/elements/Picture'
import styles from '/src/css/Masks.module.css'
import ShowMore from '@/src/components/elements/ShowMore'
import Title from '@/src/components/elements/Title'
import useBreakpointReached from '@/src/hooks/useBreakpointReached'

export function getTitle({ title }: TestimonialsSliderBlock) {
  return title
}

export default function Component({
  title,
  slider_testimonials: testimonials,
}: TestimonialsSliderBlock) {
  const mdBreakpoint = useBreakpointReached('md')

  // At least about 10 items are required to make sure to loop works
  const duplications = Math.ceil(10 / testimonials.length) - 1
  testimonials = testimonials.concat(...Array(duplications).fill(testimonials))

  return (
    <Container fullPage className="w-screen bg-blue-50 py-20">
      <Title className="mx-auto px-7 md:px-6 lg:max-w-container" style={2}>
        {title}
      </Title>
      <div className="mt-8 flex md:mx-0 md:mt-[50px]">
        <Swiper
          modules={[A11y, Autoplay]}
          a11y={{
            prevSlideMessage: 'Vorheriges Testimonial',
            nextSlideMessage: 'Nächstes Testimonial',
            paginationBulletMessage: 'Gehe zu Testimonial {{index}}',
            lastSlideMessage: 'Dies ist das letzte Testimonial',
            firstSlideMessage: 'Dies ist das erste Testimonial',
          }}
          spaceBetween={28}
          slidesPerView="auto"
          loop={true}
          autoplay={{
            delay: 0,
            pauseOnMouseEnter: true,
          }}
          speed={mdBreakpoint ? 10000 : 20000}
          allowTouchMove={false}
          className="smooth-autoplay lg:mt-2"
        >
          {testimonials.map(({ icon, author, text }, index) => (
            <SwiperSlide
              key={index}
              className="flex !w-[350px] items-center md:!w-[480px]"
            >
              <div className="rounded-lg bg-white p-8 shadow-sm">
                <div>
                  {text && (
                    <ShowMore>
                      {({ clampRef, isClamped, isExpanded, setIsExpanded }) => (
                        <>
                          <p
                            ref={clampRef}
                            className={clsx('lg:text-lg', {
                              'line-clamp-5': !isExpanded,
                            })}
                          >
                            {text}
                          </p>
                          {isClamped && !isExpanded && (
                            <button
                              onClick={() => setIsExpanded(true)}
                              className="mt-4 text-sm font-bold text-purple-600 hover:text-purple-800"
                            >
                              Mehr anzeigen
                            </button>
                          )}
                        </>
                      )}
                    </ShowMore>
                  )}
                </div>
                <div className="mt-6 flex items-center">
                  {icon && (
                    <div
                      className={classNames([
                        'relative h-[64px] w-[64px] shrink-0 overflow-hidden bg-gradient-150',
                        styles['mask-bubble'],
                        styles['mask'],
                      ])}
                    >
                      <Picture
                        {...icon}
                        sizes="64px"
                        fill
                        className="object-cover"
                      />
                    </div>
                  )}
                  {!icon && (
                    <div
                      className={classNames([
                        'relative h-14 w-14 shrink-0 overflow-hidden bg-gradient-150',
                        styles['mask-bubble'],
                        styles['mask'],
                      ])}
                    >
                      <Image
                        src={TestimonialSliderPlaceholder}
                        alt={author}
                        sizes="56px"
                        fill
                        className="object-cover"
                        unoptimized
                      />
                    </div>
                  )}
                  <div className="ml-4 font-bold lg:text-lg">
                    <p>{author}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Container>
  )
}
