import assign from 'lodash-es/assign'
import flatMap from 'lodash-es/flatMap'
import groupBy from 'lodash-es/groupBy'
import { useState } from 'react'
import { TeamAllBlock } from '../../@types/statamic'
import { getTeamMembers } from '../../AdminApiClient'
import TeamMember from '../../components/elements/TeamMember'
import Title from '../../components/elements/Title'
import Container from '../../components/layout/Container'
import {
  RadioGroupItem,
  RadioGroupRoot,
} from '@/src/components/core/RadioGroup'

type TeamBlockProps = Awaited<ReturnType<typeof getStaticProps>>

export function getTitle({ title }: TeamAllBlock) {
  return title
}

export async function getStaticProps({ title }: TeamAllBlock) {
  const team = await getTeamMembers()
  return { team, title }
}

export default function Component({ title, team }: TeamBlockProps) {
  const roles = Object.values(
    groupBy(
      flatMap(team, (team) =>
        team.roles.map((role) => assign({}, team, { role }))
      ),
      'role'
    )
  ).map((teamByRole) => teamByRole[0].role)
  const [selectedRole, setSelectedRole] = useState('ALL')

  const filteredTeam = team.filter(
    (teammember) =>
      selectedRole === 'ALL' ||
      teammember.roles.some((role) => role === selectedRole)
  )

  return (
    <div className="md:pb-12 lg:pb-16">
      <Container fullPageOnMobile>
        <div className="mx-5 lg:mx-0">
          <Title style={2}>{title}</Title>
        </div>
        <div className="w-full justify-between overflow-x-auto md:mt-4">
          <RadioGroupRoot
            value={selectedRole}
            onValueChange={setSelectedRole}
            className="flex overflow-x-auto py-3 pl-3 pr-5 lg:-ml-2 lg:px-0"
          >
            <RadioGroupItem
              value={'ALL'}
              size="filterTag"
              color="blue-outlined"
              showCheckbox={false}
              className="ml-2 mt-2"
            >
              Alle
            </RadioGroupItem>
            {roles.map((role) => (
              <RadioGroupItem
                key={role}
                value={role}
                size="filterTag"
                color="blue-outlined"
                showCheckbox={false}
                className="ml-2 mt-2"
              >
                {role}
              </RadioGroupItem>
            ))}
          </RadioGroupRoot>
        </div>
        <ul className="mt-8 grid w-full grid-cols-1 gap-x-8 gap-y-[88px] px-5 sm:grid-cols-2 lg:grid-cols-3 lg:px-0">
          {filteredTeam.map((member) => (
            <TeamMember key={member.slug} member={member} />
          ))}
        </ul>
      </Container>
    </div>
  )
}
