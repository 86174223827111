'use client'

import { X } from '@phosphor-icons/react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
  ReactNode,
  forwardRef,
} from 'react'
import { cn } from '@/core/lib/utils'

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const Dialog = (
  props: ComponentPropsWithoutRef<typeof DialogPrimitive.Root>
) => {
  return <DialogPrimitive.Root {...props} />
}

const DialogContent = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    mobileBottom?: boolean
  }
>(({ className, children, mobileBottom = true, ...props }, ref) => {
  return (
    <DialogPortal forceMount={props.forceMount}>
      <DialogPrimitive.Overlay
        className={cn(
          'fixed inset-0 z-50',
          'bg-blue-900/60',
          'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0'
        )}
      />
      <DialogPrimitive.Content
        ref={ref}
        className={cn(
          'fixed z-50 flex w-full flex-col bg-white',
          'duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-top-[5%] data-[state=open]:slide-in-from-top-[5%] lg:data-[state=closed]:slide-out-to-left-1/2 lg:data-[state=open]:slide-in-from-left-1/2',
          {
            'rounded-t-lg bottom-0 lg:left-1/2 max-h-[90%] lg:max-w-3xl lg:rounded-lg lg:bottom-auto lg:top-16 lg:max-h-[calc(100%-64px-64px)] lg:-translate-x-1/2':
              mobileBottom,
            'max-w-3xl rounded-lg top-16 inset-x-4 left-1/2 -translate-x-1/2 max-h-[calc(100%-144px)]':
              !mobileBottom,
          },
          className
        )}
        tabIndex={-1}
        {...props}
      >
        {children}
      </DialogPrimitive.Content>
    </DialogPortal>
  )
})
// @ts-ignore
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
  title,
  description,
  close = true,
  className,
  children,
  ...props
}: {
  title: string
  description?: ReactNode
  close?: boolean
} & Omit<HTMLAttributes<HTMLDivElement>, 'title'>) => {
  return (
    <div
      className={cn(
        'relative flex justify-between border-b border-blue-100 px-5 pb-3 pt-5 lg:px-8 lg:pt-6',
        className
      )}
      {...props}
    >
      <div>
        <DialogPrimitive.Title className="text-lg font-extrabold lg:text-2xl">
          {title}
        </DialogPrimitive.Title>
        {description && (
          <DialogPrimitive.Description className="inline-flex items-center text-sm text-gray-700">
            {description}
          </DialogPrimitive.Description>
        )}
        {children}
      </div>
      {close && (
        <DialogPrimitive.Close className="self-start disabled:pointer-events-none">
          <X weight="bold" size={24} />
          <span className="sr-only">Close</span>
        </DialogPrimitive.Close>
      )}
    </div>
  )
}

const DialogBody = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn('overflow-y-auto px-5 pb-8 pt-6 lg:px-8', className)}
      {...props}
    />
  )
}

const DialogSimple = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn('overflow-y-auto p-5 lg:p-6', className)} {...props} />
  )
}

export {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogBody,
  DialogSimple,
}
